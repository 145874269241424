








































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { getNameByEmail } from '@/utils/users';
import workspaceModule from '@/store/modules/workspaceModule';

@Component({

  components: {},
})
export default class TasqItem extends Vue {
  @Prop({ type: String, required: true }) tasqId!: string;

  get tasq(): TasqJob|undefined {
	  
	  if (!tasqsListModule.tasqById(this.tasqId)) {
		  return workspaceModule.tasqById(this.tasqId)
	  }
    return tasqsListModule.tasqById(this.tasqId);
  }




  get fullName(): string {
    if (this.tasq?.reassignedUserName) {
      const initialUserName = getNameByEmail(this.tasq.initialUserName).split(' ');
      const reassignedUsername = getNameByEmail(this.tasq.reassignedUserName).split(' ');
	  if (initialUserName.length > 1 && reassignedUsername.length > 1) {
		  return `${initialUserName[0][0]}.${initialUserName[1][0]} -> ${reassignedUsername[0][0]}.${reassignedUsername[1][0]}`;
	  } if (initialUserName.length > 1) {
		  return `${initialUserName[0][0]}.${initialUserName[1][0]} -> ${reassignedUsername[0][0]}`;
	  } if (reassignedUsername.length > 1) {
		  return `${initialUserName[0][0]} -> ${reassignedUsername[0][0]}.${reassignedUsername[1][0]}`;
	  }
		  return `${initialUserName[0][0]} -> ${reassignedUsername[0][0]}`;
    }
	  return getNameByEmail(this.tasq?.username);
  }



  get tasqScheduledData() {
	  for (var x = 0; x < workspaceModule.activeWorkspaces.length; x++) {
		  for (var y = 0; y < workspaceModule.activeWorkspaces[x].Tables.length; y++) {
			  for (var z = 0; z < workspaceModule.activeWorkspaces[x].Tables[y].Groups.length; z++) {
				for (var a = 0; a < workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items.length; a++) {
				  if ((this.tasq?.scheduledJobID != null && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].PredictionID != null && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].PredictionID == this.tasq?.scheduledJobID) || (this.tasq?.scheduledJobID != null && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].ID != null && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].ID == this.tasq?.scheduledJobID) || (this.tasq?.scheduledJobID != null && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].ID != null && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].ID == this.tasq?.scheduledJobID) || (workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].PredictionID != null && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].PredictionID == this.tasq?.id) || (workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].ID != null && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].ID == this.tasq?.id)) {
					  var isScheduled = false
					  var startDate = ''
					  var endDate = ''
					  for (var b = 0; b < workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns.length; b++) {
						  if (workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].ColumnType == 'STATUS' && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].CoreField && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].Response == 'Scheduled') {
							isScheduled = true
						  } else if (workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].ColumnType == 'START_DATE' && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].CoreField) {
							startDate = workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].Response
						  } else if (workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].ColumnType == 'DUE_DATE' && workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].CoreField) {
							  endDate = workspaceModule.activeWorkspaces[x].Tables[y].Groups[z].Items[a].Columns[b].Response
						  }
					  }
					  if (isScheduled) {
						var dateObj = new Date(startDate);
						var month = dateObj.getUTCMonth() + 1; //months from 1-12
						var day = dateObj.getUTCDate();
						var year = dateObj.getUTCFullYear();

						var newStartDate = month + "/" + day + "/" + year;

						var dateObj = new Date(endDate);
						var month = dateObj.getUTCMonth() + 1; //months from 1-12
						var day = dateObj.getUTCDate();
						var year = dateObj.getUTCFullYear();

						var newEndDate = month + "/" + day + "/" + year;
						  return newStartDate + " • " + newEndDate
					  }
				  }
			  	}  
			  }
		  }
	  }
	  return ''
  }




  getScheduleJobDateString(date) {
	  return date.toISOString().substring(0, 10);
  }

  //   get fullName(): string {
  // 	  return getNameByEmail(this.tasq?.username);
  //   }

  getOverridenRealTimeLabel(label) {
	  if (label.toLowerCase() == 'rtd') {
		  return 'Deferment';
	  }
	  if (label.toLowerCase() == 'hiline') {
		  return 'Line Pressure';
	  }
	  return label;
  }

  goToTasqPage() {
    // this.$router.replace({
    //   name: 'Tasqs',
    //   params: {
    //     id: this.tasq?.id || '',
    //   },
    //   query: {
    //     type: 'id',
    //   }
    // });

    tasqsListModule.setActiveTasq(this.tasq?.id || '');
    tasqsListModule.setKanbanSelectedTasq(this.tasq?.id || '');
    this.$emit('set-hide-well');
  }
}

function isTasqActive(id) {
  if (window.location.href.includes(id)) {
    return true;
  }
  return false;
}
